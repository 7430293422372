import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import destinationsService from '../../services/destinationsService';
import styles from './index.module.scss';
import { cleanedUrl } from '../../constans/locations';
import { SeoTitle } from '../../components/StructuredData/StructuredData';

function Index() {
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await destinationsService().getCountries();
      setLocations(data);
    };
    fetchData();
  }, []);
  return (
    <Layout mainClasses="section-padded-wrapper flex-grow-1">
      <SeoTitle title="List of countries on Koralgo.com" />
      <div className={styles.container}>
        <h2>List of countries on Koralgo.com</h2>
        <div className={styles.column}>
          {locations.map((item) => (
            <div>
              <a href={`/locations/${cleanedUrl(item?.name || '')}`}>
                {item.name}
              </a>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default Index;
